var firebaseConfig = {
  apiKey: "AIzaSyDI4TrAXMmelbqL3rm0VrMCzIvqZcKQnGw",
  authDomain: "bhejde-92d1d.firebaseapp.com",
  projectId: "bhejde-92d1d",
  storageBucket: "bhejde-92d1d.appspot.com",
  messagingSenderId: "354714169636",
  appId: "1:354714169636:web:b817540b24db88ba2883b1",
  measurementId: "G-G5C2WWB0B8"
};

export default firebaseConfig
